import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConstantsService {
  /** App constants go here */

  DATA_SIZE = 10000;
  SEARCH = 'search';

  // Date Format
  DATE_FORMAT = 'MM/dd/yyyy';
  DATE_FORMAT_MOMENT = 'MM/DD/YYYY';
  DATE_FORMAT_TIME = 'MM/DD/YYYY HH:mm z';
  dateServiceFormat = 'YYYY-MM-DD';
  PST_TIMEZONE = 'America/Los_Angeles';
  STAMP_FORMAT = 'YYYY-M-D-hhmmss';

  // DATA SOURCE EVENTS
  BOARD_ACTIVATING_VIEW = 'BOARD-ACTIVATING-VIEW';
  FILTER_CHANGE = 'FILTER-CHANGE';
  DATA_SOURCE_REFRESH = 'DATA SOURCE REFRESH';
  BOARD_DATA_FETCHER = 'BOARD DATA FETCHER';
  AG_GRID_SORT = 'AG-GRID-SORT';
  AG_GRID_PAGE = 'AG-GRID-PAGE';

  InterceptorSkipHeader = 'XPO-Skip-Interceptor';
}
