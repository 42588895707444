import { Injectable } from '@angular/core';
import {
  ListPricingAnalystsQuery,
  ListPricingAnalystsResp,
  ListPricingCodesQuery,
  ListPricingCodesResp,
  PricingWorkbenchApiService,
} from '@xpo-ltl/sdk-pricingworkbench';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { retryWhen, shareReplay, tap } from 'rxjs/operators';
import { UserPermission } from '../../guards/permission/permission.enum';
import { genericRetryStrategy } from '../../util/rxjs-utils';

@Injectable({
  providedIn: 'root',
})
export class PricingWorkbenchService {
  constructor(private pricingworkbenchApiService: PricingWorkbenchApiService) { }

  pricingCodesCache$ = new BehaviorSubject<ListPricingCodesResp>(null);

  listPricingAnalysts(employeeId: string): Observable<ListPricingAnalystsResp> {
    const queryParams = new ListPricingAnalystsQuery();
    queryParams.employeeId = employeeId;
    queryParams.levelOfDetail = UserPermission.security;

    return this.pricingworkbenchApiService.listPricingAnalysts(queryParams);
  }

  listPricingCodes(queryParams: ListPricingCodesQuery): Observable<ListPricingCodesResp> {
    const pricingCodes = this.pricingCodesCache$.getValue();

    return !pricingCodes
      ? this.pricingworkbenchApiService.listPricingCodes(queryParams, { loadingOverlayEnabled: false }).pipe(
        tap((data) => this.pricingCodesCache$.next(data)),
        retryWhen(
          genericRetryStrategy({
            scalingDuration: 2000,
            excludedStatusCodes: [404],
          })
        ),
        shareReplay()
      )
      : of(pricingCodes);
  }

  listPricingCodesFetch(queryParams: ListPricingCodesQuery): any {
    return this.pricingworkbenchApiService.listPricingCodes(queryParams);
  }
}
