import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { ConstantsService } from '../constants/constants.service';

@Injectable({
  providedIn: 'root',
})
export class FormatDateService {
  constructor(private datePipe: DatePipe, private constants: ConstantsService) {}

  formattingDate(date) {
    return new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 12, 34, 56, 789)
    ).toISOString();
  }

  formatDatePicker(date) {
    return new Date(moment(date).format(this.constants.dateServiceFormat));
  }

  formatDatePickerString(date) {
    return moment(date).format(this.constants.DATE_FORMAT_MOMENT);
  }

  transformMoment(date: Moment) {
    return date.utc().format(this.constants.dateServiceFormat);
  }

  transformDate(date) {
    return this.transformDateWithFormat(date, this.constants.DATE_FORMAT_MOMENT);
  }

  transformDateWithFormat(date, format): string {
    let value = date;
    if (!(date instanceof Date)) {
      value = isNaN(date) ? date : parseInt(date, 10);
    }
    return moment(value)
      .utc()
      .format(format);
  }

  transformDateTime(date) {
    return moment(date)
      .tz(this.constants.PST_TIMEZONE)
      .format(this.constants.DATE_FORMAT_TIME);
  }

  getTimeStamp() {
    const now = new Date(),
      year = now.getFullYear(),
      month = now.getMonth() + 1,
      date = now.getDate(),
      hour = now.getHours(),
      min = now.getMinutes(),
      sec = now.getSeconds();
    return year + '-' + month + '-' + date + '-' + hour + min + sec;
  }

  getDateFromJulian(data) {
    data = data === 9999999 ? 2099365 : data;
    const date = data.toString();
    const year = date.slice(0, 4);
    const days = date.slice(4, 7);
    return this.transformDate(new Date(year, 0, 0).getTime() + days * 24 * 60 * 60 * 1000);
  }

  getDateFromJulianAsDate(data) {
    data = data === 9999999 ? 2099365 : data;
    const date = data.toString();
    const year = date.slice(0, 4);
    const days = date.slice(4, 7);
    return new Date(year, 0, 0).getTime() + days * 24 * 60 * 60 * 1000;
  }
}
