import { Injectable } from '@angular/core';
import {
  GetTariffRateBlockRqst,
  Tariff,
  TariffManagementApiService,
  TariffRateBlock,
  TariffRateBlockFilter,
} from '@xpo-ltl/sdk-tariffmanagement';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TariffService } from '../tariff/tariff.service';

export interface MaxRecordsExceededMessage {
  resultCount: number;
}

@Injectable({
  providedIn: 'root',
})
export class TariffDetailService {
  private maxRecordsDisplayedSubject: BehaviorSubject<MaxRecordsExceededMessage> = new BehaviorSubject({
    resultCount: 0,
  });
  maxRecordsDisplayed$ = this.maxRecordsDisplayedSubject.asObservable();

  get MaxRecordsDisplayed() {
    return this.maxRecordsDisplayedSubject.value.resultCount;
  }

  set MaxRecordsDisplayed(value: number) {
    this.maxRecordsDisplayedSubject.next({
      resultCount: value,
    });
  }

  constructor(private tariffManagementApiService: TariffManagementApiService, private tariffService: TariffService) {}

  currentTariffSubject = new BehaviorSubject<Tariff>(null);
  currentTariff$ = this.currentTariffSubject.asObservable();

  get currentTariff() {
    return this.currentTariffSubject.value;
  }

  loadDetail(tariffData): void {
    this.currentTariffSubject.next(null);
    this.tariffService.getTariff(tariffData).subscribe((tariff) => {
      this.currentTariffSubject.next(tariff);
    });
  }

  getTariffRateBlocks(filters: TariffRateBlockFilter): Observable<TariffRateBlock[]> {
    const request = new GetTariffRateBlockRqst();
    request.tariffRateBlockFilter = filters;
    return this.tariffManagementApiService.getTariffRateBlock(request).pipe(
      map((data) => {
        const tariffRateBlocks = this.removeWhiteSpaces(data.tariffRateBlocks);
        return tariffRateBlocks;
      })
    );
  }

  removeWhiteSpaces(data: TariffRateBlock[]) {
    return data.reduce((acc, tariff) => {
      const nmfcClasses = tariff.nmfcClasses.reduce((acc2, nmfcClass) => {
        const weightBrackets = nmfcClass.weightBrackets.map((weightBracket) => {
          return { name: weightBracket.name.trim(), value: weightBracket.value };
        });
        nmfcClass.weightBrackets = weightBrackets;
        return [...acc2, nmfcClass];
      }, []);
      tariff.nmfcClasses = nmfcClasses;
      return [...acc, tariff];
    }, []);
  }
}
