export enum AppRoutes {
  LANDING_PAGE = '',
  DATA_ENTRY_PAGE = 'data-entry-page',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  TARIFF_LIST = 'tariff-list',
  TARIFF_DETAIL = 'tariff-detail',
  TRANSIT_TIME_FILE_REQUEST = 'transit-time-file-request',
  RATE_FILE_REQUEST = 'rate-request',
  FILE = 'file',
  ROOT = '/',
  BID_FILE_REQUEST = 'bid-file-request',
}
