import { Component, OnInit } from '@angular/core';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TariffDetailService } from '../../../shared/services/tariff-detail/tariff-detail.service';

@Component({
  selector: 'app-column-key-renderer',
  templateUrl: './column-key-renderer.component.html',
  styleUrls: ['./column-key-renderer.component.scss'],
})
export class ColumnKeyRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  tariffId: string;
  tariffDetailTray: MatDrawerContainer;

  constructor(private tariffDetailService: TariffDetailService) {}

  agInit(params: any): void {
    this.tariffId = params.value;
    this.tariffDetailTray = params.tariffDetailTray;
    this.params = params;
  }

  refresh(params: any): boolean {
    this.tariffId = params.value;
    this.tariffDetailTray = params.tariffDetailTray;
    this.params = params;
    return true;
  }

  openDetail() {
    this.tariffDetailService.loadDetail(this.params.data);
    this.tariffDetailTray.open();
  }
}
