export enum TariffFieldLabels {
  tariffCode = 'RTAR',
  effectiveAsOfDate = 'Effective As Of',
  effectiveDate = 'Effective date',
  expirationDate = 'Expiration date',
  carRate = 'CAR Rate',
  rateWareXL = 'Rate Ware XL',
  carrier = 'Carrier',
  description = 'Description',
  comments = 'Comments',
  tariffRef = 'Tariff Ref',
  costing = 'Costing',
}
