import { Injectable } from '@angular/core';
import { User } from '@xpo-ltl/sdk-common';
import { AdminPersonnel, ListPricingCodesQuery, PricingCode } from '@xpo-ltl/sdk-pricingworkbench';
import _ from 'lodash';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, retryWhen, skipWhile, switchMap, tap } from 'rxjs/operators';
import { UserPermission } from '../../guards/permission/permission.enum';
import { genericRetryStrategy } from '../../util/rxjs-utils';
import { PricingWorkbenchService } from '../pricing-workbench/pricing-workbench.service';
import { UserService } from './user.services';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionService {
  userPermissions = new BehaviorSubject<Array<string>>(undefined);
  userPermissions$ = this.userPermissions.asObservable();

  constructor(private pricingworkbenchService: PricingWorkbenchService, private userService: UserService) {
    this.userService.user$.pipe(skipWhile((user) => !user)).subscribe((user: User) => {
      this.loadPermissions(user);
    });
  }

  loadPermissions(user: User) {
    if (user && user.employeeId) {
      this.getPermissions(user).subscribe((permissions) => {
        this.userPermissions.next(permissions);
      });
    }
  }

  getPermissions(user: User): any {
    if (user && user.employeeId) {
      return this.pricingworkbenchService.listPricingAnalysts(user?.employeeId).pipe(
        map((resp) => resp.pricingAnalysts.filter((item) => item.employeeId === user.employeeId)),
        switchMap((personnel: AdminPersonnel[]) => {
          const employeeRoleType = personnel[0].employeeRoleType ? personnel[0].employeeRoleType.trim() : '';
          const additionalRoleType = personnel[0].additionalRoleType ? personnel[0].additionalRoleType.trim() : '';
          const roles = [];
          if (employeeRoleType && !roles.includes(employeeRoleType)) {
            roles.push(employeeRoleType);
          }
          if (additionalRoleType) {
            additionalRoleType.split(',').forEach((additionalRole) => {
              const role = additionalRole ? additionalRole.trim() : '';
              if (role && !roles.includes(role)) {
                roles.push(role);
              }
            });
          }

          const params = new ListPricingCodesQuery();
          params.category = [UserPermission.tariffManagement];
          params.subCategory = [UserPermission.security];

          return this.pricingworkbenchService.listPricingCodes(params).pipe(
            tap((resp) => this.pricingworkbenchService.pricingCodesCache$.next(resp)),
            map((data) => data.pricingCodes),
            switchMap((response: PricingCode[]) => {
              const permissions = _.filter(response, (permission) => {
                return roles.includes(permission.code) ? true : false;
              }).map((permission: PricingCode) => permission.name);
              const result = [...new Set(permissions)];
              return of(result);
            })
          );
        }),
        retryWhen(
          genericRetryStrategy({
            scalingDuration: 2000,
            excludedStatusCodes: [404],
          })
        ),
        catchError(() => {
          return of([]);
        })
      );
    }
  }

  hasPermission(permission: string): any {
    if (this.userPermissions.value) {
      return of(this.userPermissions.value.includes(permission));
    } else {
      return this.userService.getUser().pipe(
        switchMap((user: User) => {
          return this.getPermissions(user).pipe(
            map((data: any) => {
              return data.includes(permission);
            })
          );
        })
      );
    }
  }
}
