export enum UserPermission {
  tariffDetail = 'tariffDetail',
  tariffList = 'tariffList',
  transitTimeFile = 'transitTimeFile',
  bidFile = 'bidFile',
  rateFile = 'rateFile',
  autoGri = 'autoGri',
  tariffManagement = 'TariffManagement',
  security = 'Security',
}
