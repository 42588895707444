<xpo-shell class="main-shell">
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>{{ title }}</xpo-header-sub-app-name>
  <xpo-header-utilities-actions>
    <mat-icon>search</mat-icon>
    <xpo-notification-popover [notifications]="notifications"></xpo-notification-popover>
    <xpo-auth-user-profile>
      <xpo-auth-user-profile-links>
        <a [href]="AccountUrls.myAccount" target="_blank">My Account</a>
      </xpo-auth-user-profile-links>
    </xpo-auth-user-profile>
  </xpo-header-utilities-actions>
  <xpo-header-navigation>
    <ng-container *ngFor="let route of userRoutes$ | async; let i = index">
      <button [routerLink]="route.path" routerLinkActive="xpo-selected">
        {{ route.label }}
      </button>
    </ng-container>
  </xpo-header-navigation>
  <router-outlet></router-outlet>
</xpo-shell>
<xpo-footer xpoFooterSticky [version]="build">
  <a xpo-regular-link href="mailto: {{ email }}" color="accent">Feedback</a>
</xpo-footer>
