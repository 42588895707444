export enum TariffFieldNames {
  tariffCode = 'tariff',
  effectiveAsOfDate = 'effectiveAsOfDate',
  effectiveDate = 'effectiveDate',
  expirationDate = 'expirationDate',
  carRate = 'carRate',
  rateWareXL = 'rateWareXL',
  carrier = 'ratewareRef.carrier',
  description = 'description',
  comments = 'remark',
  tariffRef = 'ratewareRef.tariffRef',
  costing = 'singleShipmentCharge',
}
